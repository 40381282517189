const features = {
	demo: false,
	'companies.industries': false,
	'ai.chat': true,
	'ai.email': 'alpha',
	'event.invite': true,
	'video.recorder': false,
	'create.workspace': 'alpha',
	'befare.next': 'alpha',
	'sms.custom': true,
	'smart.demo': true,
	insight: false,
	customers: false,
	'project.create': false,
	sidekick: true,
	smartchat: true,
	onboarding: false,
	'pwa.install.banner': true,
};

export default features;
