export enum LocalStorageKey {
	// eslint-disable-next-line no-unused-vars
	ShowAdvancedFields = 'settings:createProject:showAdvancedFields',
	// eslint-disable-next-line no-unused-vars
	ShowHeatmap = 'settings:dashboard:map:showHeatmap',
	// darkmode
	// eslint-disable-next-line no-unused-vars
	DarkMode = 'settings:darkMode',
}

export default class LocalStorageUtil {
	static setItem(key: LocalStorageKey, value: unknown): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	static getItem<T>(key: LocalStorageKey): T | undefined {
		const item = localStorage.getItem(key);
		if (!item) {
			return undefined;
		} else {
			try {
				return JSON.parse(item) as T;
			} catch (error) {
				return undefined;
			}
		}
	}

	static removeItem(key: LocalStorageKey): void {
		localStorage.removeItem(key);
	}
}
