import { Card, CardProps } from '@mui/material';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';

type Props = {
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
} & CardProps;

const ElevatedCard = observer(
	class ElevatedCard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		elevation: number | undefined;
		disposer: IReactionDisposer | null = null;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				elevation: observable,
				disposer: observable,
				rootStore: computed,
				uiState: computed,
				isDesktop: computed,
				isOutlined: computed,
				initElevation: action,
				handleMouseEnter: action,
				handleMouseOut: action,
				dispose: action,
			});
		}

		componentDidMount() {
			this.initElevation();
		}

		componentDidUpdate() {
			this.disposer = reaction(
				() => this.isDesktop,
				() => this.initElevation()
			);
		}

		componentWillUnmount() {
			this.dispose();
		}

		dispose() {
			if (this.disposer) {
				this.disposer();
			}
			this.disposer = null;
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get isDesktop() {
			return this.uiState.isDesktop;
		}

		get isOutlined() {
			return this.isDesktop && this.elevation === 0;
		}

		initElevation() {
			this.elevation = this.isDesktop ? 0 : 1;
		}

		handleMouseEnter = () => {
			if (!this.isDesktop) {
				return;
			}

			this.elevation = 4;
		};

		handleMouseOut = () => {
			if (!this.isDesktop) {
				return;
			}

			this.elevation = 0;
		};

		render() {
			const { children, style, ...rest } = this.props;

			return (
				<Card
					elevation={this.elevation}
					variant={this.isOutlined ? 'outlined' : undefined}
					onMouseOver={this.handleMouseEnter}
					onMouseOut={this.handleMouseOut}
					sx={(theme) => ({
						padding: this.isDesktop && !this.isOutlined ? '1px' : undefined,
						backgroundColor: theme.palette.background.paper,
						color: theme.palette.text.primary,
						...style,
					})}
					{...rest}
				>
					{children}
				</Card>
			);
		}
	}
);

export default ElevatedCard;
