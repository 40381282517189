import { CheckOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, AlertProps, Box, Stack, CircularProgress } from '@mui/material';
import { isArray } from 'lodash';
import React from 'react';

type FullWidthAlertProps = {
    title: string;
    customContent: string | string[];
    lastItemInProgress?: boolean;
} & AlertProps;

export default class FullWidthAlert extends React.Component<FullWidthAlertProps> {
    renderContent() {
        const { customContent, content, lastItemInProgress } = this.props;
        if (isArray(customContent)) {
            // lets  just work with the last 10 items in the array
            const items = customContent.slice(-10);
            return items.map((line, index) => (
                <Stack key={index} direction="column" spacing={2} alignItems="flex-start">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        {lastItemInProgress && index === items.length - 1 ? (
                            // progress circle
                            <CircularProgress size={16} />
                        ) : (
                            <CheckOutlined color="success" />
                        )}
                        {line}
                    </Box>
                </Stack>
            ));
        } else {
            return customContent ?? content;
        }
    }

    render() {
        const { title } = this.props;
        return (
            <Alert
                {...this.props}
                sx={{
                    width: '100%',
                    textAlign: 'left',
                    borderRadius: '.5rem',
                    margin: '1rem 0',
                }}
            >
                <AlertTitle>{title}</AlertTitle>
                {this.renderContent()}
            </Alert>
        );
    }
}
