import { makeAutoObservable, reaction } from 'mobx';
import { RootStore } from '../RootStore';
import { Trigger } from '../../Trigger.types';
import Agent from './Agent';
import AgentsApi from '../../api/endpoints/AgentsApi';
import { AgentDTO } from '../../components/dashboard2/assistant/agent.types';

class AgentStore {
	rootStore: RootStore;
	myAgents: Agent[] = [];
	storeAgents: Agent[] = [];

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;

		reaction(
			() => this.rootStore.workspaceStore.hasEmployeeAccess,
			async (hasEmployeeAccess) => {
				console.info('AgentStore: User logged in status changed', hasEmployeeAccess);
				if (hasEmployeeAccess) {
					await this.init();
				} else {
					this.reset();
				}
			}
		);
	}

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	processTrigger = async (trigger: Trigger<any>) => {
		try {
			console.info('AgentStore: Processing trigger', trigger);
		} catch (err) {
			console.warn(err);
		}
	};

	/**
	 * Initializes the store by fetching assistants.
	 */
	async init(): Promise<void> {
		this.reset();
		if (this.rootStore.workspaceStore.hasEmployeeAccess) {
			await this.loadMyAgents();
		}
	}

	/**
	 * Resets the store.
	 */
	reset(): void {
		this.myAgents = [];
	}

	/**
	 * Fetches all assistants.
	 */
	async loadMyAgents(): Promise<void> {
		try {
			const response = await AgentsApi.getAgents();
			if (response.statusCode === 200 && response.data) {
				response.data.forEach((agentData: AgentDTO) => this.updateOrCreateAgent(agentData));
			}
		} catch (error) {
			console.error('Error fetching assistants: ', error);
		}
	}

	async loadAgent(agentId: number): Promise<void> {
		try {
			const response = await AgentsApi.getAgent(agentId);
			if (response.statusCode === 200 && response.data) {
				this.updateOrCreateAgent(response.data);
			}
		} catch (error) {
			console.error('Error fetching assistant: ', error);
		}
	}

	/**
	 * Updates an existing assistant or creates a new one.
	 * @param {AssistantDTO} assistantData - Data for the assistant.
	 */
	updateOrCreateAgent(agentData: AgentDTO): void {
		const existingAssistantIndex = this.myAgents.findIndex((agent) => agent.agentId === agentData.agent.agentId);
		if (existingAssistantIndex > -1) {
			this.myAgents[existingAssistantIndex].updateFromDTO(agentData);
		} else {
			const newAgent = new Agent(agentData);
			this.myAgents.push(newAgent);
		}
	}

	findAgentById(agentId: number): Agent | undefined {
		return this.myAgents.find((agent) => agent.agentId === agentId);
	}

	// Additional methods as needed, such as createAssistant, getAssistant, etc.
}

export default AgentStore;
