import { Button, Typography } from '@mui/material';
import { makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import WorkspaceList from './WorkspaceList';
import './WorkspacePicker.scss';
import { Workspace } from '../../types';

type Props = {};

const WorkspacePicker = observer(
	class WorkspacePicker extends React.Component<Props> {
		static readonly contextType = StoreContext;
		workspaces: Workspace[] = [];
		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				uiState: computed,
				profileStore: computed,
				userStore: computed,
			});

			this.workspaces = AsyncStorageHelper.getCache(`@BefWeb:login:workspaces`) || [];
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		componentDidMount(): void {
			if (this.workspaces.length === 0) {
				this.loginAsCustomer();
			}
		}

		loginAsCustomer = () => {
			AsyncStorageHelper.setCache(`@BefWeb:login:redirect`, '/app/dashboard');
			this.userStore.switchWorkspace();
		};

		render() {
			const { isMobile, isTablet, isPortraitOrientation } = this.uiState;

			return (
				<div className="body WorkspacePicker">
					<Typography
						align="center"
						gutterBottom
						variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
						sx={(theme) => ({
							color: theme.palette.text.primary,
							marginTop: '1rem',
							marginBottom: '1rem',
						})}
					>
						Velkommen,{' '}
						<span className="text--bold text--italic text--red" data-hj-suppress>
							{this.profileStore.currentUserProfile?.name}
						</span>
						!
					</Typography>
					<Typography align="center" color="textSecondary" paragraph variant={isMobile ? 'body2' : 'body1'}>
						Velg workspacet du vil logge på, eller fortsett som sluttkunde
					</Typography>
					<WorkspaceList />
					<Button
						className="continue-as-customer-button"
						color="primary"
						fullWidth
						onClick={this.loginAsCustomer}
						variant="contained"
					>
						Fortsett som sluttkunde
					</Button>
				</div>
			);
		}
	}
);

export default WorkspacePicker;
